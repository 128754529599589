import React from "react"
import ArticleCard from '../ArticleCard/ArticleCard'
import "./PostListing.scss"


function PostListing(props) {
  const {postEdges} = props
  
  function getPostList() {
    const postList = []
    postEdges.forEach( (postEdge) => {
      postList.push({
        slug: postEdge.node.fields.slug,
        thumb: postEdge.node.frontmatter.thumb,
        category: postEdge.node.frontmatter.category,
        tags: postEdge.node.frontmatter.tags,
        title: postEdge.node.frontmatter.title,
      })
    })
    return postList
  }

  const postList = getPostList()
  return (
    <div className="container grid grid--outer postlisting" style={{ paddingTop: "0" }}>
      {postList.map((post, i) => (
        <ArticleCard
          frontMatter={post}
          fields={post}
          key={i}
        />
      ))}
    </div>
  )
}

export default PostListing
