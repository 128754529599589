import { Link } from "gatsby"
import React from "react"
import Logo from "../../../static/assets/theme/logo-pp--simple.inline.svg"
import "./PostHeader.scss"

function PostHeader(props) {
  const { config: {siteTitle, siteTitleAlt} } = props
  return (
    <div className="page__header" role="banner">
      <Link to="/" className="page__header__link">
        <Logo 
          style={{
            height: "var(--scale--3)",
            marginRight: "1ch",
            transform: "translateY(-0.3em)",
            verticalAlign: "middle",
          }}
        />
        <span className="h2 page__header__link__logo" style={{fontSize:"var(--scale--2)"}}>{siteTitle}</span>
        &nbsp;
        <span className="page__header__link__tagline h3">{siteTitleAlt}</span>
      </Link>
    </div>
  );
}

export default PostHeader