import { graphql } from "gatsby"
import React from "react"
import Helmet from "react-helmet"
import config from "../../data/SiteConfig"
import Footer from "../components/Footer/Footer"
import PostHeader from "../components/PostHeader/PostHeader"
import PostListing from "../components/PostListing/PostListing"
import SectionHeading from "../components/SectionHeading/SectionHeading"
import Layout from "../components/Layout/Layout"

export default class TechTemplate extends React.Component {
  render() {
    const { tech } = this.props.pageContext;
    const postEdges = this.props.data.allMarkdownRemark.edges
    return (
      <Layout>
        <Helmet title={`Posts using "${tech}" | ${config.siteTitle}`} />
        <PostHeader config={config}/>
        <div className="container grid grid--outer" style={{ paddingBottom: "0", paddingTop: "var(--spacing--0)" }}>
          <SectionHeading
            title={`${tech} Posts`}
            id="skip__target"
            pageHeading
          />
        </div>
        <PostListing postEdges={postEdges} />
        <Footer config={config} />
      </Layout>
    )
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query TechPage($tech: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { tech: { in: [$tech] }, draft: { ne: true }, hidden: {ne: true} } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            date
          }
          frontmatter {
            title
            tags
            thumb {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED, 
                  width: 400, 
                  height: 400, 
                  quality: 80,
                  formats: [AUTO, WEBP]
                )
              }
            }
            date
            category
          }
        }
      }
    }
  }
`
