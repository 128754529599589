import React from "react"
import "./SectionHeading.scss"

function SectionHeading(props) {
  const { title, id, pageHeading, sub, inverse } = props
  const light = inverse ? "inverse" : ""
  
  function getHeading() {
    if (pageHeading) {
      return (<h1 id={id} className="h2 section-heading__title">{title}</h1>)
    }
    return (<h2 id={id} className="h2 section-heading__title">{title}</h2>)
  }

  return (
    <div className={`section-heading ${light}`}>
      {getHeading()}
      <span className="section-heading__sub">{sub}</span>
    </div>
  )
}

export default SectionHeading
